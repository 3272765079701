// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arrangement-js": () => import("./../../src/pages/arrangement.js" /* webpackChunkName: "component---src-pages-arrangement-js" */),
  "component---src-pages-campus-bergen-makerspace-js": () => import("./../../src/pages/campus/bergen/makerspace.js" /* webpackChunkName: "component---src-pages-campus-bergen-makerspace-js" */),
  "component---src-pages-campus-js": () => import("./../../src/pages/campus.js" /* webpackChunkName: "component---src-pages-campus-js" */),
  "component---src-pages-faste-arrangement-js": () => import("./../../src/pages/faste-arrangement.js" /* webpackChunkName: "component---src-pages-faste-arrangement-js" */),
  "component---src-pages-finansiering-js": () => import("./../../src/pages/finansiering.js" /* webpackChunkName: "component---src-pages-finansiering-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nyheter-js": () => import("./../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-om-oss-js": () => import("./../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-personvern-js": () => import("./../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-rektors-utfordring-js": () => import("./../../src/pages/rektors-utfordring.js" /* webpackChunkName: "component---src-pages-rektors-utfordring-js" */),
  "component---src-pages-tjenester-js": () => import("./../../src/pages/tjenester.js" /* webpackChunkName: "component---src-pages-tjenester-js" */),
  "component---src-templates-single-campus-js": () => import("./../../src/templates/single-campus.js" /* webpackChunkName: "component---src-templates-single-campus-js" */),
  "component---src-templates-single-event-js": () => import("./../../src/templates/single-event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-news-js": () => import("./../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */)
}

