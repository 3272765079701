module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150807949-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-theme-material-foundry/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HVL Skape","short_name":"Skape","start_url":"/","background_color":"#004357","theme_color":"#eb6851","display":"minimal-ui","icon":"src/images/skape_icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"705c58dd5b087efe886b76e4987f9dab"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
